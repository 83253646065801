@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  background-color: $page-bg;

  &:global(.has-sidebar) {
    .wrapper {
      @media (min-width: $sidebar-breakpoint) {
        display: table;
        width: 100%;
        position: relative;

        .row {
          display: table;
          width: 100%;
        }

        .content {
          display: table-cell;
          position: relative;
          width: 100%;
          max-width: 0;
          padding-right: $gutter-width * 0.5;
        }

        .sidebar-wrapper {
          display: table-cell;
          vertical-align: top;
          padding-left: $gutter-width * 0.5;
        }
      }
    }
  }
}
