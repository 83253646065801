@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  float: left;

  > .menu {
    @include clearfix;

    li {
      @media screen and (min-width: $nav-bar-breakpoint) {
        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    > li {
      $link-padding-y: 0.3rem;
      $link-height: (
        ($nav-bar-font-size * $nav-bar-line-height) + $link-padding-y * 2
      );
      position: relative;
      float: left;
      padding: ($nav-bar-height - $link-height) * 0.5 0;

      a {
        display: block;
        padding: $link-padding-y 1.7rem;
        letter-spacing: 0.36em;
        color: $color-gray;
        text-transform: uppercase;
        border-right: 1px dotted $color-light-cream;
        transition: color 0.25s;

        &:hover {
          color: $link-color;
          text-decoration: none;
        }
      }

      @media (min-width: $sidebar-breakpoint) {
        &:first-child {
          a {
            padding-left: 0;
          }
        }
      }

      &:last-child {
        a {
          border-right: 0;
        }
      }

      &[aria-current='page'] {
        a {
          color: $link-color;
        }
      }

      ul {
        background: transparentize($nav-bar-bg, 0.1);
        opacity: 0;
        position: absolute;
        z-index: 1000;
        visibility: hidden;
        transition: opacity 0.25s linear;
        width: 100%;

        > li {
          position: relative;
          width: 100%;
          padding: 0.25em 0;
          transition: background-color 0.25s ease-in;

          > a {
            letter-spacing: 0.15em;
            color: $color-gray !important;
            text-align: center;
            text-transform: lowercase !important;
            border: 0;
          }

          &:hover {
            background-color: $nav-bar-bg;

            > a {
              color: $color-aqua !important;
            }

            ul {
              display: block;
            }
          }
        }
      }

      > ul {
        top: 100%;
        left: 0;

        > li {
          > ul {
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            visibility: hidden;
          }
        }
      }
    }
  }

  @media screen and (max-width: $nav-bar-breakpoint) {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 100;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s ease 200ms, opacity 200ms ease;

    .menu {
      position: relative;
      background-color: $nav-bar-bg;
      border-bottom: 1px solid $nav-bar-border-color;

      > li {
        float: none;

        a {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          text-align: center;
          border-right: 0;
        }
      }
    }

    &:global(.show) {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}
