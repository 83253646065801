@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  margin-top: $footer-margin-sm;
  letter-spacing: 0;

  @media (min-width: $sidebar-breakpoint) {
    margin-top: $footer-margin;
  }

  :global(.recent-posts) + & {
    margin-top: 0;
  }

  @media screen and (max-width: 751px) {
    text-align: center;
  }

  .footer-content {
    background-color: $body-bg;

    :global(.container) {
      @include clearfix;
      $padding-y: 3rem;
      padding-top: $padding-y;
      padding-bottom: $padding-y;

      @media (max-width: $sidebar-breakpoint - 1) {
        padding-left: $gutter-width;
        padding-right: $gutter-width;
      }
    }
  }

  > .instagram {
    position: relative;

    .account-link {
      position: absolute;
      z-index: 10;
      background: rgba(#fff, 0.8);
      display: block;
      padding: 0.3em 1em;
      left: 50%;
      top: 50%;
      font-size: 1.4em;
      transform: translateX(-50%) translateY(-50%);
      transition: background 200ms ease-in, color 200ms ease-in;

      &:hover,
      &:active {
        text-decoration: none;
        background-color: rgba(#fff, 0.9);
        color: darken($link-color, 15%);
      }
    }

    .instagram-images {
      @include clearfix;
      margin: 0 0 0 -1px;

      > li {
        $num: 11;
        width: percentage(math.div(1, $num));
        float: left;
        padding-left: 1px;

        &:nth-child(n + #{$num + 1}) {
          display: none;
        }

        $min-num: 3;
        $max-num: 10;
        $max-size: 225px;
        @mixin media-query($i) {
          $max-width: $i * $max-size;
          $min-width: $max-width - ($max-size - 1px);
          @if ($i == $min-num) {
            @media (max-width: #{$max-width}) {
              @content;
            }
          } @else {
            @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
              @content;
            }
          }
        }

        @for $i from $min-num through $max-num {
          @include media-query($i) {
            width: percentage(math.div(1, $i));

            &:nth-child(n + #{$i + 1}) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.site-info {
  float: left;

  @media screen and (max-width: 751px) {
    float: none;
  }
}

.social {
  float: right;

  ul {
    &.links {
      display: inline-block;

      li {
        float: left;

        a {
          display: block;
          width: 1.5em;
          height: 1.5em;
          padding: 0 0.5em;
          color: $color-brown;
          transition: color 0.25s;

          &:last-child {
            padding-right: 0;
          }

          svg {
            width: 100%;
            height: 100%;
            transition: fill 0.25s;
            fill: $text-color;
          }

          &:hover {
            color: $link-color;
            text-decoration: none;

            svg {
              fill: $link-color;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 751px) {
    float: none;
    margin-top: 10px;
  }
}
