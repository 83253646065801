@import 'variables';
@import 'mixins';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $body-bg;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  font-kerning: normal;
}

.container {
  width: $container-width-base;
  max-width: $container-max-width;
  min-width: $container-min-width;
  margin: 0 auto;

  @media screen and (max-width: $container-breakpoint) {
    width: 100%;
    max-width: inherit;
    min-width: inherit;
    margin: 0;
  }
}

main {
  > h1:first-child,
  > div:first-child > h1:first-child,
  > header > h1:first-child,
  > .entry-header,
  > article > header:first-child,
  > article:first-child {
    margin-top: 0 !important;
  }

  > h1 {
    padding: $gutter-width-y * 0.5 $gutter-width-x * 0.5 0;

    @media (min-width: $container-breakpoint) {
      padding: 0;
    }
  }
}

body {
  font-size: $body-font-size;
  line-height: $body-line-height;
  // letter-spacing: 2 / 30 + em;
  color: $text-color;
}

body,
button,
input,
select,
textarea {
  font-family: 'chaparral-pro-n4', 'chaparral-pro-1', 'chaparral-pro-2',
    'Garamond', serif;
}

strong {
  font-family: 'chaparral-pro-n6', 'chaparral-pro-1', 'chaparral-pro-2',
    'Garamond', serif;
  font-weight: 600;
}

em {
  font-family: 'chaparral-pro-i4', 'chaparral-pro-1', 'chaparral-pro-2',
    'Garamond', serif;
  font-style: italic;
}

strong em,
em strong {
  font-family: 'chaparral-pro-i6', 'chaparral-pro-1', 'chaparral-pro-2',
    'Garamond', serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &,
  a {
    clear: both;
    font-family: 'ratiomodern-n4', 'ratiomodern-1', 'ratiomodern-2', 'Garamond',
      serif;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    color: $headings-color;
    transition: color 0.25s;
    margin: 0;
  }

  a {
    &:hover {
      color: $link-color;
      text-decoration: none;
    }
  }

  small {
    font-size: 0.6em;
    margin-left: 1em;
    opacity: 0.6;
    text-transform: lowercase;
    text-decoration: underline;

    a {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 100ms, opacity 100ms ease-in;
    }
  }

  &:hover {
    small {
      a {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
    }
  }

  strong {
    font-family: 'ratiomodern-n4', 'ratiomodern-1', 'ratiomodern-2', 'Garamond',
      serif;
    font-weight: 600;
  }

  em {
    font-family: 'ratiomodern-n4', 'ratiomodern-1', 'ratiomodern-2', 'Garamond',
      serif;
    font-style: italic;
  }
}

h1 {
  margin: 0.75em 0;
  font-family: 'ratiomodern-i4', 'ratiomodern-1', 'ratiomodern-2', 'Garamond',
    serif;
  font-size: 2em;
  font-style: italic;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.3em;
}

p {
  p + & {
    margin-top: 1.8em;
  }
}

input[type='submit'],
button.btn {
  padding: 0.4em 1.75em;
  color: #fff;
  cursor: pointer;
  background-color: $button-color;
  border: 1px solid $button-border-color;
  font-weight: bold;
  transition: background-color 200ms ease-in, border-color 200ms ease-in;

  &:hover {
    background-color: darken($button-color, 10%);
    border-color: darken($button-border-color, 15%);
  }

  &:active {
    transition: none;
    background-color: darken($button-color, 20%);
    border-color: darken($button-border-color, 25%);
  }

  &.disabled,
  &:disabled {
    $disabled-button-color: lighten($button-color, 10%);
    cursor: not-allowed;
    background: $disabled-button-color;
    border-color: darken($disabled-button-color, 10%);
  }

  &.btn-link {
    padding: 0;
    margin: 0;
    color: $link-color;
    text-shadow: none;
    background: transparent;
    border: 0;
  }

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: #6e9ab5;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:active {
    outline: 0;
    text-decoration: underline;
  }

  img {
    border: 0;
  }
}

ol,
ul {
  &.menu,
  &.submenu,
  &.links {
    @include list-unstyled;
    margin-top: 0;
    margin-bottom: 0;
  }
}

img {
  &:global(.can-click) {
    cursor: pointer;
  }
}

@import 'forms';
