input[type='text'],
input[type='email'],
input[type='url'],
textarea {
  border: 0;
  background-color: $page-bg;
  padding: $input-padding-top $input-padding-x $input-padding-bottom;
  width: 100%;
  color: $text-color;

  &:focus {
    outline: none;
  }
}

.form-group {
  padding: 0.75em 0;

  .control-label {
    font-size: 0.9em;
    color: $color-gray;
  }

  .form-control-feedback {
    font-size: 0.8em;
    color: $color-gray;
    padding-top: 0.5em;

    &:empty {
      padding: 0;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.has-danger {
    .control-label,
    .form-control-feedback {
      color: $state-danger-text;
    }
  }

  &.required {
    label {
      &::after {
        font-size: 1.25em;
        color: $state-danger-text;
        content: '*';
      }
    }
  }
}

form {
  &.horizontal {
    .form-group {
      @include clearfix;

      @media (min-width: 35rem) {
        .control-label,
        .control-wrapper {
          float: left;
        }
      }

      $sizes: (
        35rem: 30%,
        68rem: 20%,
      );

      @each $min, $label-width in $sizes {
        @media (min-width: $min) {
          .control-label {
            width: $label-width;
          }

          .control-wrapper {
            width: 100% - $label-width;
          }
        }
      }
    }
  }
}
