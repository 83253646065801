@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.logo {
  > a {
    position: relative;

    > img {
      position: static;
      display: block;
      width: auto;
      height: auto;
    }
  }
}

.root {
  &:global(.container) {
    margin-top: $nav-bar-height;
  }

  .nav-bar {
    position: fixed;
    background-color: $nav-bar-bg;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid $nav-bar-border-color;
    font-size: $nav-bar-font-size;
    line-height: $nav-bar-line-height;

    > :global(.container) {
      @include clearfix;
      position: relative;
      min-height: $nav-bar-height;
    }

    .logo {
      $height: $nav-bar-height * 0.45;
      $padding-y: ($nav-bar-height - $height) * 0.5;
      text-align: center;

      a {
        display: block;
        height: $height + $padding-y * 2;
        padding: $padding-y 0;
      }

      img {
        height: 100%;
        width: auto;
        display: inline;
      }

      @media (min-width: $nav-bar-breakpoint) {
        display: none;
      }
    }

    .search {
      $search-height: $nav-bar-font-size * $nav-bar-line-height +
        (math.div($input-padding-top + $input-padding-bottom, 1em) * 1rem) +
        $input-border-width * 2;
      float: right;

      :global(.form-group) {
        padding: ($nav-bar-height - $search-height) * 0.5 0;

        input {
          height: $search-height;
        }

        :global(.control-wrapper) {
          position: relative;

          :global(.form-control-feedback) {
            position: absolute;
            background-color: rgba($nav-bar-bg, 0.5);
            left: -1em;
            right: -1em;
            padding-left: 1em;
            padding-right: 1em;
            padding-bottom: 0.5em;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  @media (max-width: $nav-bar-breakpoint - 1) {
    .header-image {
      display: none;
    }

    #page.home & {
      .nav-bar {
        border-bottom: 0;

        .logo {
          display: none;
        }
      }

      .header-image {
        display: block;
        background-color: $body-bg;
        padding: 1rem 1rem 3rem;
        height: auto;

        a {
          height: auto;
        }

        img {
          width: 100%;
          height: auto;
          position: relative;
        }
      }
    }
  }
}

button {
  &.navbar-toggle,
  &.search-toggle {
    $size: $nav-bar-height;
    background-color: transparent;
    background-image: none;
    border: 0;
    padding: 0;
    width: $size;
    height: $size;
    padding: math.div($nav-bar-height, 3) 0;

    &:focus {
      outline: 0;
    }

    svg {
      width: math.div($size, 3);
      height: math.div($size, 3);
      transition: fill 0.25s;
      fill: $color-gray;
    }

    &:hover,
    &.active {
      svg {
        fill: $link-color;
      }
    }

    @media screen and (max-width: $nav-bar-breakpoint) {
      position: absolute;
      top: 0;

      &:hover:not(.active) {
        svg {
          fill: $color-gray;
        }
      }
    }
  }

  &.navbar-toggle {
    display: none;
    left: 0;

    @media screen and (max-width: $nav-bar-breakpoint) {
      z-index: 100;
      display: block;
    }
  }

  &.search-toggle {
    cursor: pointer;

    @media (min-width: $nav-bar-breakpoint) {
      float: right;
    }

    @media (max-width: $nav-bar-breakpoint) {
      right: 0;
    }
  }
}

.search-form {
  float: right;
  margin-right: 0.5em;
  transition: opacity 200ms ease;
  margin-bottom: 0;

  @media (max-width: $nav-bar-breakpoint) {
    float: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $nav-bar-bg;
    border-bottom: 1px solid $nav-bar-border-color;
    width: 100%;
    padding: 0 1rem;

    input {
      width: 100%;
      font-size: 1rem;
      height: auto !important;
      padding: 0.5em;
    }
  }

  p {
    &:global(.required) {
      &::after {
        content: none;
      }
    }
  }

  &:global(.fade-enter) {
    opacity: 0;

    &:global(.fade-active) {
      opacity: 1;
    }
  }

  &:global(.fade-leave) {
    opacity: 1;

    &:global(.fade-leave-active) {
      opacity: 0;
    }
  }
}

.header-image {
  position: relative;
  transition: opacity 200ms ease;
  padding: 1px 0;

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;

    > span {
      max-width: 591px;
      margin: 0 auto !important;
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    margin: auto;

    @media screen and (max-width: 940px) {
      width: 70%;
      height: auto;
    }

    @media screen and (max-width: $nav-bar-breakpoint) {
      width: 90%;
    }
  }

  @media screen and (max-width: 940px) {
    height: 20vw;
    margin-top: 0;
  }
}
